import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import '../css/navbar.css';

const BottomNavbar = ({activeBar}) => {

    return (
      <div className="row-view">

        <nav className='btm-navbar ps-fx ps-btm z-100 bg-black res-wth'>
            
          <Link className={`nav-col trn-05 ${activeBar=="home" ? 'navBtnAnimate' : ''}`} to={"/home"}>
            {activeBar=="home" ? <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="currentColor"><path d="m108.708 31.125 56.346 35.92a12.496 12.496 0 0 1 5.779 10.543v76.708a12.501 12.501 0 0 1-12.5 12.5l-43.75-.004v-50.125H87.5v50.125l-41.667.008a12.5 12.5 0 0 1-12.5-12.5V76.5a12.5 12.5 0 0 1 5.913-10.625l56.158-34.833a12.5 12.5 0 0 1 13.308.083h-.004Z"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="#FFFFFF"><path d="m108.708 31.125 56.346 35.92a12.496 12.496 0 0 1 5.779 10.543v76.708a12.501 12.501 0 0 1-12.5 12.5h-49.846l.005-38.475H95.833v38.475h-50a12.5 12.5 0 0 1-12.5-12.5V76.504a12.5 12.5 0 0 1 5.913-10.625l56.158-34.833a12.5 12.5 0 0 1 13.308.083l-.004-.004ZM158.333 154.3V77.583l-56.341-35.916L45.833 76.5v77.8h37.5v-38.479h37.659v38.475h37.341v.004Z"></path></svg>}
            <p>Home</p>
          </Link>

          <Link className={`nav-col ${activeBar=="invite" ? 'navBtnAnimate' : ''}`} to={"/invite"}>
            {activeBar=="invite" ? <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="currentColor"><path d="m118.171 116.792 4.741 5.533.005 11.008h-16.763V162.5h16.763l-.03 8.333h-81.22a12.496 12.496 0 0 1-11.356-7.276 12.498 12.498 0 0 1 1.864-13.357l28.633-33.408A53.911 53.911 0 0 0 89.488 125a53.926 53.926 0 0 0 28.683-8.208ZM143.654 125v16.667h16.667v12.5h-16.667v16.666h-12.5v-16.666h-16.667v-12.5h16.667V125h12.5ZM89.488 29.167c23.012 0 41.666 18.654 41.666 41.666 0 23.013-18.654 41.667-41.666 41.667-23.013 0-41.667-18.654-41.667-41.667 0-23.012 18.654-41.666 41.666-41.666Z"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="#FFFFFF"><path d="m34.354 150.2 28.633-33.408A54.12 54.12 0 0 0 74.73 122.3l-30.883 36.033h64.487v12.5H43.846a12.496 12.496 0 0 1-11.356-7.276 12.498 12.498 0 0 1 1.864-13.357ZM145.833 125v16.667H162.5v12.5h-16.667v16.666h-12.5v-16.666h-16.666v-12.5h16.666V125h12.5ZM91.667 29.167c23.012 0 41.666 18.654 41.666 41.666 0 23.013-18.654 41.667-41.666 41.667C68.654 112.5 50 93.846 50 70.833c0-23.012 18.654-41.666 41.667-41.666Zm0 12.5a29.167 29.167 0 1 0 0 58.333 29.167 29.167 0 0 0 0-58.333Z"></path></svg>}
            <p>Invite</p>
          </Link>

          <Link className={`nav-col ${activeBar=="recharge" ? 'navBtnAnimate' : ''}`} to={"/recharge"}>
            {activeBar=="recharge" ? <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="currentColor"><path d="M124.888 40a11.998 11.998 0 0 1 12 12l-.004 8.176H149.1a11.998 11.998 0 0 1 12 12l-.004 20.284h-56.492v32.284h56.492v20.284c0 3.183-1.264 6.235-3.515 8.485a11.996 11.996 0 0 1-8.485 3.515H48a11.998 11.998 0 0 1-12-12V60a20 20 0 0 1 20-20h68.888Zm35.716 64.46v8.284h-44v-8.28h44v-.004ZM124.88 52H56a8 8 0 0 0-8 8l-.004.176h76.888V52h-.004Z"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="#FFFFFF"><path d="M161.1 145.028c0 3.183-1.264 6.235-3.515 8.485a11.996 11.996 0 0 1-8.485 3.515H48a11.998 11.998 0 0 1-12-12V60a20 20 0 0 1 20-20h68.888a12 12 0 0 1 12 12l-.004 8.176H149.1a11.998 11.998 0 0 1 12 12v72.852Zm-12-72.852H48v72.852h101.1l-.004-20.284h-44.492v-32.28l44.492-.004V72.18l.004-.004Zm0 32.284h-32.5v8.284h32.496v-8.28l.004-.004ZM124.888 52H56a8 8 0 0 0-8 8l-.004.176h76.888V52h.004Z"></path></svg>}
            <p>Recharge</p>
          </Link>

          <Link className={`nav-col ${activeBar=="my" ? 'navBtnAnimate' : ''}`} to={"/my"}>
            {activeBar=="my" ? <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="currentColor"><path d="M52.2 170.8c-6.9 0-12.5-5.6-12.5-12.5 0-3 1.1-5.9 3-8.1l31.4-36.6c7.8 4.8 16.8 7.3 25.9 7.2 9.5 0 18.4-2.6 25.9-7.2l31.4 36.6c4.5 5.2 3.9 13.1-1.4 17.6-2.3 1.9-5.1 3-8.1 3M100 29.2c23 0 41.7 18.7 41.7 41.7 0 23-18.7 41.6-41.7 41.6-23 0-41.7-18.7-41.7-41.7 0-23 18.7-41.6 41.7-41.6Z"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="#FFFFFF"><path d="m125.937 113.587 31.375 36.613a12.5 12.5 0 0 1-9.491 20.633H52.179a12.496 12.496 0 0 1-11.356-7.276 12.497 12.497 0 0 1 1.864-13.357l31.38-36.609a49.553 49.553 0 0 0 11.954 5.263l-33.842 39.479h95.642l-33.842-39.479a49.73 49.73 0 0 0 11.958-5.267ZM100 29.167c23.012 0 41.667 18.654 41.667 41.666 0 23.013-18.655 41.667-41.667 41.667-23.013 0-41.667-18.654-41.667-41.667 0-23.012 18.654-41.666 41.667-41.666Zm0 12.5A29.167 29.167 0 1 0 100 100a29.167 29.167 0 0 0 0-58.334Z"></path></svg>}
            <p>My</p>
          </Link>

        </nav>

      </div>
    );
};

export default BottomNavbar;