import React, { useRef,useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import { API_ACCESS_URL } from '../modals/Constants';
import { getStorage,deleteStorage } from '../modals/Storage';

function RechargeRecords(){
    const navigate = useNavigate();
    const listViewRef = useRef();
    const [pageConst, setConstants] = useState({
        pageTitle: "Recharge Records",
        isLoadingShow: false,
        pageCount: 1,
        recordList: [],
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn2"){
          navigate('/addbankcard', { replace: false });
        }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const onScroll = () => {
      if (listViewRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listViewRef.current;
        const isNearBottom = scrollTop + clientHeight >= scrollHeight;

        if (isNearBottom) {
          let newPageVal = pageConst.pageCount + 1;
          setConstants(previousState => {
            return { ...previousState, pageCount: newPageVal }
          });
        }
      }
    }

    const updateRecordList = (data) => {
      let tempData = [];

      if(pageConst.recordList.length > 0){
        tempData = pageConst.recordList;
      }

        for (let i = 0; i < data.length; i++) {
            let rechargeDetailsArr = data[i]['r_details'].split(',');

            tempData.push(
            <div key={i} className='pr-v w-100 br-5 mg-t-15 bg-extm-l-white br-a-l-blue'>
                <div className={`ps-ab ps-tp ps-lf pd-2-8 br-5 ft-sz-15 cl-white ${data[i]['r_status']=="success" ? 'bg-green' : (data[i]['r_status']=="rejected" ? 'bg-red' : 'bg-l-black')}`}>{data[i]['r_status']=="success" ? 'completed' : (data[i]['r_status']=="rejected" ? 'cancelled' : 'processing')}</div>
                <div className='ps-ab ps-tp ps-rgt pd-2-8 ft-sz-15'>{data[i]['r_date']+" "+data[i]['r_time']}</div>

                <div className='col-view pd-5-15'>

                  <div className='pr-v col-view w-100 ft-sz-14 mg-t-20'>
                    <span className='ft-sz-15 mg-t-5'>By: UTRPay</span>
                    <span className='ft-sz-15 mg-t-5'>To: <span className='cl-blue'>{rechargeDetailsArr[1]}</span></span>

                    <div className={`ps-ab ps-btm ps-rgt pd-2-8 ft-sz-25 ${data[i]['r_status']=="success" ? 'cl-green' : (data[i]['r_status']=="rejected" ? 'cl-red' : '')}`}>₹{data[i]['r_amount']}</div>
                  </div>

                </div>
            </div>)
        };

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }


    function getRechargeRecords(pageCount){
        const fecthApiData = async (url) => {
            try {
              const res = await fetch(url, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'route': 'route-recharge-records',
                  'AuthToken': getStorage("secret"),
                }
              });

              const data = await res.json();
              updateLoadingStatus(false);

              if(data.status_code="success"){
                updateRecordList(data.data); 
              }
            } catch (error) {
              updateLoadingStatus(false);
            }
        };

        updateLoadingStatus(true);
        fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PAGE_NUM="+pageCount);
    }
  
    useEffect(() => {
      if(getStorage("uid")){
        getRechargeRecords(pageConst.pageCount);
        const listInnerElement = listViewRef.current;
  
        if (listInnerElement) {
          listInnerElement.addEventListener("scroll", onScroll);
  
          // Clean-up
          return () => {
            listInnerElement.removeEventListener("scroll", onScroll);
          };
        }
      }else{
        if(deleteStorage()){
          navigate('/login', { replace: true });
        }
      }
    }, [pageConst.pageCount]);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-app-back" ref={listViewRef}>
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
          <LoadingDialog intentData={pageConst}/>

          <div className="col-view mg-t-45">

            <div className="col-view br-right-t br-left-t mg-b-15">

               <div className='col-view pd-5-15'>
                  {pageConst.recordList}
                </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default RechargeRecords;