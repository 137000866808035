import React, { useEffect, useState, useCallback } from 'react'
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import YouTube from 'react-youtube';
import { saveAs } from 'file-saver';
import { API_ACCESS_URL,LOGIN_REDIRECT_URL,getURLParam,redirectTo,
  generateAuthToken,copyText } from '../modals/Constants';
import { setStorage,getStorage } from '../modals/Storage';
import { Link,useNavigate } from 'react-router-dom';
import '../../MainStyle.css';

function SuperPay(){
    const navigate = useNavigate();
    const qrCode = require('qrcode');
    const [isInputValCorrect, setInValCorrect] = useState(false);
    const [qrCompleteLink, setQrCompleteLink] = useState("");

    const [pageConst, setConstants] = useState({
        pageTitle: "Super Pay",
        inPaymentUpi: "",
        inPaymentAmount: getURLParam('amount'),
        timeLeftInSeconds: -1,
        paymentRefNumber: "",
        paymentStatusCode: 1,
        dataShouldLoad: true,
        isLoadingShow: false,
        toastDialogShow: false,
        toastMessage: "",
        tabActive: "qr",
    });

    const opts = {
      height: '220',
      width: window.innerWidth,
      playerVars: {
        autoplay: 0,
        showinfo: 0,
        controls: 0,
      },
    }

    const handleVisibilityChange = useCallback(() => {
      let isVisible = document.visibilityState === 'visible';

      if(isVisible){
        checkPaymentStatus();
      }
    }, []);

    const updateActiveTab = (data) => {
      setConstants(previousState => {
          return { ...previousState, tabActive: data }
      });
    }

    function setUpQrCode(upi,amount,note){
      let qrData = `upi://pay?pa=${upi}&pn=Merchant&cu=INR&am=${amount}&tn=${note}&tr=WHATSAPP_QR`;

      // Converting the data into base64 
      qrCode.toDataURL(encodeURI(qrData), function (err, code) {
        if(err){
          return console.log("error occurred")
        }

        // Printing the code
        setQrCompleteLink(code);
      })
    }

    const downloadQRCode = () =>{
      if(qrCompleteLink!=""){
        saveAs(qrCompleteLink, 'payment-qrcode.jpg') // Put your image URL here.
      }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const checkForInputVal = (newpassword,password) =>{
      if(newpassword != "" && newpassword != undefined && password!="" && password != undefined){
        if(newpassword==password && newpassword.length >= 6){
          setInValCorrect(true);
        }else{
          setInValCorrect(false);
        }
      }else{
        setInValCorrect(false);
      }
    }

    const checkPaymentStatus = () => {
      const requestAPI = async (url) => {
        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-check-payment',
              'AuthToken': generateAuthToken(Number(getStorage("uid"))),
            }
          });

          const data = await res.json();

          updateLoadingStatus(false);

          if(data.status_code=="authorization_error"){
            updateToastDialogState(true,"Authorization Error!");
          }else if(data.status_code=="success"){
            
            setConstants(previousState => {
              return { ...previousState, paymentStatusCode: 2 }
            });

            setConstants(previousState => {
              return { ...previousState, paymentRefNumber: data.payment_refnum }
            });

          }else if(data.status_code=="no_record"){
            updateToastDialogState(true,"Sorry! No Transactions Found. Please try again!");
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };


      if(getStorage("inPaymentId")!=""){
        updateLoadingStatus(true);
        requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PAYMENT_ID="+getStorage("inPaymentId"));
      }
    }

    const generatePayment = () => {
      const requestAPI = async (url) => {
        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-setup-payment',
              'AuthToken': generateAuthToken(Number(getStorage("uid"))),
            }
          });

          const data = await res.json();

          updateLoadingStatus(false);

          if(data.status_code=="authorization_error"){
            updateToastDialogState(true,"Authorization Error!");
          }else if(data.status_code=="success"){

            setStorage("inPaymentId",data.payment_id, 30);

            setConstants(previousState => {
              return { ...previousState, inPaymentUpi: data.payment_upi }
            });

            setConstants(previousState => {
                return { ...previousState, inPaymentAmount: data.payment_amount }
            });

            setConstants(previousState => {
                return { ...previousState, timeLeftInSeconds: data.payment_time_left }
            });

            setUpQrCode(data.payment_upi,data.payment_amount,data.payment_id);
            
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(getStorage("uid")!=""){
        updateLoadingStatus(true);
        requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&RECHARGE_AMOUNT="+getURLParam('amount'));
      }
    }

    function requestData(){
      if(pageConst.dataShouldLoad){
        setConstants(previousState => {
          return { ...previousState, dataShouldLoad: false }
        });

        generatePayment();
      }
    }

    const copyTextNow = (data) =>{
      copyText(data);
      updateToastDialogState(true,"Copied!");
    }


    useEffect(() => {
      if(pageConst.paymentStatusCode==1){
        document.addEventListener("visibilitychange", handleVisibilityChange);
      }

      const interval = setInterval(() => {
        if(pageConst.timeLeftInSeconds > 0){
            setConstants(previousState => {
              return { ...previousState, timeLeftInSeconds: pageConst.timeLeftInSeconds - 1 }
            });
        }else if(pageConst.timeLeftInSeconds == 0 && pageConst.paymentStatusCode==1){
          setConstants(previousState => {
            return { ...previousState, paymentStatusCode: 4 }
          });
        }
      }, 1000);

      if(!getStorage("uid")){
        redirectTo(LOGIN_REDIRECT_URL);
      }else if(getURLParam('amount')!=null && getURLParam('amount')!=""){
        requestData();
      }else{
        redirectTo(LOGIN_REDIRECT_URL);
      }

      //Clearing the interval
      return () => {
        clearInterval(interval);
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      }

    }, [pageConst.timeLeftInSeconds,pageConst.paymentStatusCode]);

    return (
      <div className='v-center'>
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2=""/>
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className="game-manage-view col-view mg-t-45">

            <div className={`pd-10-15 bg-l-blue ${pageConst.paymentStatusCode > 1 ? 'hide-v' : ''}`}>
              <p>{getStorage("inPaymentId") != '' ? 'Time: '+pageConst.timeLeftInSeconds+' Seconds' : 'Loading...'}</p>
            </div>

            <div className={`col-view w-100 v-center pd-20 ${pageConst.paymentStatusCode > 1 ? 'hide-v' : ''}`}>

              {/* <div className='w-100 row-view sb-view'>
                <div className='col-view'>
                  <div>Step</div>
                  <div className={`h-w-36 v-center br-50 mg-t-5 ${pageConst.paymentStage >= 1 ? 'cl-white bg-blue' : 'bg-l-blue'}`}>1</div>
                </div>

                <div className={`w-100 h-5-p br-r-5 ${pageConst.paymentStage >= 2 ? 'cl-white bg-blue' : 'bg-l-blue'}`}></div>

                <div className='col-view'>
                  <div>Step</div>
                  <div className={`h-w-36 v-center br-50 mg-t-5 ${pageConst.paymentStage >= 2 ? 'cl-white bg-blue' : 'bg-l-blue'}`}>2</div>
                </div>

                <div className={`w-100 h-5-p br-r-5 ${pageConst.paymentStage >= 3 ? 'cl-white bg-blue' : 'bg-l-blue'}`}></div>

                <div className='col-view'>
                  <div>Step</div>
                  <div className={`h-w-36 v-center br-50 mg-t-5 ${pageConst.paymentStage >= 3 ? 'cl-white bg-blue' : 'bg-l-blue'}`}>3</div>
                </div>
              </div> */}

                {/* <div className='w-100 row-view'>

                    <div className='w-100 h-50-p v-center br-10 bg-l-blue'>
                      <img className="h-20-p" src={require('../icons/payments/upi-icon.png')} />
                    </div>

                    <div className='w-100 h-50-p v-center mg-l-10 br-10 bg-l-blue'>
                      <img className="h-30-p" src={require('../icons/payments/qr-code-icon.png')} />
                    </div>

                </div> */}

                <div className='w-100 col-view pd-10'>
                  <div className='row-view'>
                    <p className='ft-sz-16'>₹</p>
                    <p className='ft-sz-35 mg-l-10'>{getStorage("inPaymentId") != '' ? pageConst.inPaymentAmount : '0'}</p>
                  </div>

                  <p className='ft-sz-14'>Powered by SuperPay</p>
                </div>
                

                {/* <div className='w-100 col-view pd-10 mg-t-20 hide-v'>
                  <p className='ft-sz-13'>Pay ₹0 to this upi 👇👇</p>

                  <div className='w-100 row-view sb-view'>
                    <div className='ft-sz-20'>demo7854@paytm</div>
                    <div className='h-w-36 v-center br-50 bg-l-blue'>
                      <img className="h-20-p" src={require('../icons/payments/copy-icon.png')} />
                    </div>
                  </div>
                </div> */}

                <div className="tab-slct-v w-100 mg-t-25">
                  <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActive=="qr" ? 'active' : ''}`} onClick={()=>updateActiveTab('qr')}>QR Code</div>
                  <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActive=="upi" ? 'active' : ''}`} onClick={()=>updateActiveTab('upi')}>UPI</div>
                </div>

                <div className={`w-100 col-view mg-t-20 ${pageConst.tabActive!="qr" ? 'hide-v' : ''}`}>
                  <p className='ft-sz-13'>Pay ₹{pageConst.inPaymentAmount} to this QR Code 👇👇</p>

                  <div className='w-100 col-view pd-10 mg-t-10 br-5 br-a-l-white'>

                    <div className='v-center br-5'>
                      <img className="h-200-p" src={qrCompleteLink} />
                    </div>

                    <div className='row-view v-center mg-t-10' onClick={() => downloadQRCode()}>
                      <div className='h-w-32 v-center br-50 bg-green'>
                        <img className="h-15-p" src={require('../icons/payments/download-icon-white.png')} />
                      </div>
                      <p className='mg-l-10'>Download</p>
                    </div>

                    <div className='ft-sz-12 mg-t-15'>
                      Take a screenshot or download the qr code to pay. Don't use old qr code for payment. Your Payment Id is Payment Id: {getStorage("inPaymentId")}
                    </div>
                    
                  </div>
                </div>

                <div className={`w-100 col-view mg-t-20 ${pageConst.tabActive!="upi" ? 'hide-v' : ''}`}>
                  <div className='ps-rl w-100 pd-10 br-5 bg-l-grey'>
                    <p className='ft-sz-14'>Step 1: Copy UPI Id</p>
                    <p className='ft-sz-18 mg-t-5'>{pageConst.inPaymentUpi}</p>

                    <div className='ps-ab ps-tp-50 t-form-y ps-rgt-10 h-w-32 br-50 col-view v-center bg-grey-2' onClick={() => copyTextNow(pageConst.inPaymentUpi)}>
                      <img className="h-15-p" src={require('../icons/payments/copy-icon-white.png')} />
                    </div>
                  </div>

                  <div className='ps-rl w-100 pd-10 br-5 mg-t-10 bg-l-grey'>
                    <p className='ft-sz-14'>Step 2: Enter this Id in Note</p>
                    <p className='ft-sz-18 mg-t-5'>{getStorage("inPaymentId")}</p>

                    <div className='ps-ab ps-tp-50 t-form-y ps-rgt-10 h-w-32 br-50 col-view v-center bg-grey-2' onClick={() => copyTextNow(getStorage("inPaymentId"))}>
                      <img className="h-15-p" src={require('../icons/payments/copy-icon-white.png')} />
                    </div>
                  </div>
                </div>

                <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-10 cl-white bx-shdw-blk bg-blue`} onClick={() => checkPaymentStatus()}>
                  <img className={`smpl-btn-l spin-anim ${pageConst.isLoadingShow==false ? 'hide-v' : ''}`} src={require('../icons/loader-icon.png')} />
                  <span className={`${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Check Payment</span>
                </div>

                <div className='line-hv-grey mg-t-55'></div>

                <div className='w-100 col-view mg-t-55'>
                  <p className='ft-sz-18'>Instruction 👉 How to Pay?</p>
                  <p className='mg-t-10'>1) Copy upi or scan qr code.</p>
                  <p className='mg-t-10'>2) Pay ₹{pageConst.inPaymentAmount} to copied UPI Id.</p>
                  <p className='mg-t-10'>2) Comeback & Click 'Check Payment'.</p>
                  <p className='mg-t-10'>2) After verification amount will be credited.</p>

                  <p className='ft-sz-18 mg-t-30'>Watch Demo 👉 How to Pay?</p>

                  <YouTube videoId="JxIN5fruFFo" opts={opts} className='res-wth v-center mg-t-15'/>
                </div>

            </div>

            <div className={`w-100 col-view v-center mg-t-55 ${pageConst.paymentStatusCode!=2 ? 'hide-v' : ''}`}>
              <img className="h-70-p" src={require('../icons/payments/check-icon.png')} />

              <p className='ft-sz-20 mg-t-15'>Payment Successful</p>
              <p className='ft-sz-13 mg-t-10'>RefNumber: {pageConst.paymentRefNumber}</p>
              <p className='ft-sz-18 mg-t-10'>₹{Number(pageConst.inPaymentAmount).toFixed(2)}</p>
            </div>

            <div className={`w-100 col-view v-center pd-0-30 mg-t-55 ${pageConst.paymentStatusCode!=3 ? 'hide-v' : ''}`}>
              <img className="h-70-p" src={require('../icons/payments/error-icon.png')} />

              <p className='ft-sz-20 mg-t-15'>Payment Failed</p>
              <p className='ft-sz-13 mg-t-10'>Payment Id: {getStorage("inPaymentId")}</p>
              <p className='ft-sz-13 mg-t-10'>Note: Payment failed due to some internal issue.</p>
            </div>

            <div className={`w-100 col-view v-center pd-0-30 mg-t-55 ${pageConst.paymentStatusCode!=4 ? 'hide-v' : ''}`}>
              <img className="h-70-p" src={require('../icons/payments/error-icon.png')} />

              <p className='ft-sz-20 mg-t-15'>Payment Failed</p>
              <p className='ft-sz-13 mg-t-10'>Payment Id: {getStorage("inPaymentId")}</p>
              <p className='ft-sz-13 mg-t-10'>Note: Payment failed due to timeout.</p>
            </div>

          </div>
        </div>
      </div>
    );
}

export default SuperPay;