import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import { API_ACCESS_URL,IP_ACCESS_URL, generateAuthToken } from '../modals/Constants';
import { setStorage,getStorage } from '../modals/Storage';

function Login(){
  const navigate = useNavigate();
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [pageConst, setConstants] = useState({
      pageTitle: "Login",
      inMobileNum: "",
      inPassword: "",
      isLoadingShow: false,
      toastDialogShow: false,
      toastTimeAvail: 7,
      toastMessage: "",
      isSessionExist: true,
  });

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){
        navigate('/withdraw', { replace: false });
      }

      if(data=="multiBtn2"){
        navigate('/register', { replace: false });
      }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const checkForInputVal = (mobilenum,password) =>{
      if(mobilenum != "" && mobilenum != undefined && password!="" && password != undefined){
        if(mobilenum.length == 10 && password.length >= 6){
          setInValCorrect(true);
        }else{
          setInValCorrect(false);
        }
      }else{
        setInValCorrect(false);
      }
    }

    const onInputValChange = (source,data) =>{

      if(source=="mobile"){
        checkForInputVal(data, pageConst.inPassword);

        setConstants(previousState => {
          return { ...previousState, inMobileNum: data }
        });
      }

      if(source=="password"){
        checkForInputVal(pageConst.inMobileNum, data);

        setConstants(previousState => {
          return { ...previousState, inPassword: data }
        });
      }
    }

    const validateMyIP = () =>{
      updateLoadingStatus(true);

      const requestAPI = async (url) => {
        try {
          const res = await fetch(url, {
            method: 'GET',
          });

          const data = await res.json();
          if(data.ip!=""){
            return validateLogin(data.ip);
          }else{
            return "IPUNKNOWN";
          }

        } catch (error) {
          return "IPOUTERROR";
        }
      };

      requestAPI(IP_ACCESS_URL);
    }

    
    const validateLogin = (ip) => {
      async function requestAPI(url,formData) {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'route': 'route-login',
              'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
            },
            body: JSON.stringify(formData),
          });
          
          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code=="account_suspended"){
            updateToastDialogState(true,"Sorry! This Account is suspended!");
          }else if(data.status_code=="user_not_exist"){
            updateToastDialogState(true,"Account not exist!");
          }else if(data.status_code=="password_error"){
            updateToastDialogState(true,"Password not correct! try again");
          }else if(data.status_code=="success"){
            setStorage("uid",data.data[0].account_id, 30);
            setStorage("mobile",data.data[0].account_mobile_num, 30);
            setStorage("balance",data.data[0].account_balance, 30);
            setStorage("secret",data.data[0].auth_secret_key, 30);
            navigate('/home', { replace: true });
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(isInputValCorrect){
        const formData = { LOGIN_ID: pageConst.inMobileNum,LOGIN_PASSWORD: pageConst.inPassword};
        requestAPI(API_ACCESS_URL,formData);
      }
    }

    useEffect(() => {
      if(getStorage("uid")){
        navigate('/home', { replace: true });
      }else{
        setConstants(previousState => {
          return { ...previousState, isSessionExist: false }
        });
      }
    }, []);

    return (
      <div className={`v-center bg-black ${pageConst.isSessionExist == true ? 'hide-v' : ''}`}>
        <div className="h-100vh res-wth bg-app-back">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className='pr-v ovf-scrl-y hide-sb zoomAnimView'>

            <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Register" updateState={topBarClickAction}/>

            <div className='col-view w-100 v-center pd-5-15 mg-b-15 mg-t-45'>
                <img className="h-70-p br-5" src={require('../icons/logo.jpg')} />

                <div className='cutm-inp-bx pd-5-15 br-5 mg-t-15'>
                    <img className="h-w-22" src={require('../icons/phone_icon.png')} />
                    <input type="number" name='ssjsjg' className='mg-l-20 ft-sz-18 cutm-inp' placeholder='Enter Mobile Number' autoComplete="off" onChange={e => onInputValChange('mobile',e.target.value)}></input>
                </div>

                <div className='cutm-inp-bx pd-5-15 br-5 mg-t-5'>
                    <img className="h-w-22" src={require('../icons/lock_icon.png')} />
                    <input type="password" className='mg-l-20 ft-sz-18 cutm-inp' placeholder='Password (≥6 characters)' autoComplete="new-password" onChange={e => onInputValChange('password',e.target.value)}></input>
                </div>

                <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white ${isInputValCorrect ? 'bg-blue' : 'bg-grey-2'}`} onClick={() => validateMyIP()}>
                  <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow==false ? 'hide-v' : ''}`}></div>
                  <span className={`${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Login</span>
                </div>

                <div className='w-100 v-center ft-sz-16 mg-t-20'>
                    <span className='cl-white'>Don't remember password?</span>
                    <Link className='txt-deco-n mg-l-10 cl-blue' to={"/forgotpassword"}>Forgot Password</Link>
                </div>
            </div>

          </div>
        </div>
      </div>
    );
}

export default Login;