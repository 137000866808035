import React, { useEffect, useState, useRef } from 'react';
import { useNavigate  } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import { API_ACCESS_URL,IP_ACCESS_URL,
  getURLParam,generateAuthToken } from '../modals/Constants';
import { setStorage,getStorage } from '../modals/Storage';

function ForgetPassword(){
  
    const Ref = useRef(null);
    const navigate = useNavigate();
    const [resendOTPTimeLeft, setResendOTPTime] = useState(0);
    const [isInputValCorrect, setInValCorrect] = useState(false);

    const [pageConst, setConstants] = useState({
        pageTitle: "Forget Password",
        inMobileNum: "",
        inPassword: "",
        inConfirmPassword: "",
        inVerificationCode: "",
        isLoadingShow: false,
        toastDialogShow: false,
        toastTimeAvail: 7,
        toastMessage: "",
        isSessionExist: true,
        isOTPSending: false,
        resendOTPTimeLimit: 60,
    });

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateOTPSendingStatus = (data) =>{
      setConstants(previousState => {
        return { ...previousState, isOTPSending: data }
      });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const isMobileNumValidate = (mobilenum) =>{
      if(mobilenum != "" && mobilenum != undefined && mobilenum.length == 10){
        return true;
      }else{
        return false;
      }
    }

    const isPasswordValidate = (password,confirmPassword) =>{
      if(password!="" && password != undefined && password.length >= 6 && password==confirmPassword){
        return true;
      }else{
        return false;
      }
    }

    const checkForInputVal = (mobilenum,password,confirmPassword,verificationCode) =>{
      if(isMobileNumValidate(mobilenum) && isPasswordValidate(password,confirmPassword) && verificationCode.length > 4){
        setInValCorrect(true);
      }else{
        setInValCorrect(false);
      }
    }

    const onInputValChange = (source,data) =>{
      if(source=="mobile"){
        checkForInputVal(data, pageConst.inPassword, pageConst.inConfirmPassword, pageConst.inVerificationCode);

        setConstants(previousState => {
          return { ...previousState, inMobileNum: data }
        });
      }

      if(source=="password"){
        checkForInputVal(pageConst.inMobileNum, data, pageConst.inConfirmPassword, pageConst.inVerificationCode);

        setConstants(previousState => {
          return { ...previousState, inPassword: data }
        });
      }

      if(source=="confirmPassword"){
        checkForInputVal(pageConst.inMobileNum, pageConst.inPassword, data, pageConst.inVerificationCode);

        setConstants(previousState => {
          return { ...previousState, inConfirmPassword: data }
        });
      }

      if(source=="verificationCode"){
        checkForInputVal(pageConst.inMobileNum, pageConst.inPassword, pageConst.inConfirmPassword, data);

        setConstants(previousState => {
          return { ...previousState, inVerificationCode: data }
        });
      }
    }

    const validateMyIP = (route) =>{
      updateLoadingStatus(true);

      const requestAPI = async (url) => {
        try {
          const res = await fetch(url, {
            method: 'GET',
          });

          const data = await res.json();
          if(data.ip!=""){
            if(route=="OTP"){
              return sendVerificationCode(data.ip);
            }else{
              return validateResetPassword(data.ip);
            }
          }else{
            return "IPUNKNOWN";
          }

        } catch (error) {
          return "IPOUTERROR";
        }
      };

      requestAPI(IP_ACCESS_URL);
    }

    const validateResetPassword = (ip) => {
      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-reset-password',
              'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
            },
            body: JSON.stringify(formData),
          });
          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code=="invalid_otp"){
            updateToastDialogState(true,"OTP is incorrect ! Please enter correct OTP!");
          }else if(data.status_code=="success"){
            updateToastDialogState(true,"Password Changed !");
          }else if(data.status_code=="account_error"){
            updateToastDialogState(true,"Sorry, There is an error related to account !");
          }else if(data.status_code=="invalid_mobile_num"){
            updateToastDialogState(true,"Invalid mobile number !");
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(isInputValCorrect){
        updateLoadingStatus(true);
        const formData = { MOBILE: pageConst.inMobileNum,NEW_PASSWORD: pageConst.inConfirmPassword,USER_OTP: pageConst.inVerificationCode};
        requestAPI(API_ACCESS_URL,formData);
      }
    }

    const getTimeRemaining = (e) => {
      const total = Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      return {
          total, seconds
      };
    }

    const startCountDownTimer = (e) =>{
      let { total, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        setResendOTPTime((seconds > 9 ? seconds : '0' + seconds))
      }
    }

    const getDeadTime = (e) => {
      let deadline = new Date();

      deadline.setSeconds(deadline.getSeconds() + e);
      return deadline;
    }
    
    const clearTimer = (e) => {
      setResendOTPTime(30);

      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        startCountDownTimer(e);
      }, 1000)
      Ref.current = id;
    }

    const sendVerificationCode = (ip) =>{
      const requestAPI = async (url,formData) => {
        try {
          const res = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-send-sms',
              'AuthToken': generateAuthToken(pageConst.inMobileNum,ip),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          updateLoadingStatus(false);
          updateOTPSendingStatus(false);

          if(data.status_code=="otp_error"){
            updateToastDialogState(true,"We failed to send OTP ! Please try again!");
          }else if(data.status_code=="account_error"){
            updateToastDialogState(true,"Sorry, There is an error related to account !");
          }else if(data.status_code=="authorization_error"){
            updateToastDialogState(true,"Authorization Error !");
          }else if(data.status_code=="otp_limit_error"){
            updateToastDialogState(true,"You've used daily limited OTP ! Please try later!");
          }else if(data.status_code=="success"){
            setStorage("otptimeout",getDeadTime(pageConst.resendOTPTimeLimit), 30);
            clearTimer(getDeadTime(pageConst.resendOTPTimeLimit));
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateOTPSendingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(isMobileNumValidate(pageConst.inMobileNum)){
        if(resendOTPTimeLeft > 0){
          updateLoadingStatus(false);
          updateToastDialogState(true,"Please after sometime!");
        }else if(pageConst.isOTPSending==false){
          updateOTPSendingStatus(true);
          const formData = { MOBILE: pageConst.inMobileNum,PURPOSE: "RESETPASSWORD"};
          requestAPI(API_ACCESS_URL,formData);
        }
      }else{
        updateLoadingStatus(false);
        updateToastDialogState(true,"Mobile Number is incorrect!");
      }
    }

    useEffect(() => {
      if(getStorage("uid")){
        navigate('/home', { replace: true });
      }else{

        setConstants({...pageConst, isSessionExist: false});

        if(getStorage("otptimeout")){
          let { total, seconds } = getTimeRemaining(getStorage("otptimeout"));
          if(seconds > 0){
            clearTimer(getDeadTime(seconds));
          }
        }

        if(getURLParam('C')!=null && getURLParam('C')!=""){
          let referCode = getURLParam('C');
          setConstants(previousState => {
            return { ...previousState, inInviteCode: referCode }
          });
        }
        
      }
    }, []);

    return (
      <div className={`v-center bg-black ${pageConst.isSessionExist == true ? 'hide-v' : ''}`}>
        <div className="h-100vh res-wth bg-app-back">
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

          <div className='pr-v ovf-scrl-y hide-sb zoomAnimView'>
            <TopBar intentData={pageConst} multiBtn={false} multiBtn1="" multiBtn2=""/>

            <div className='col-view w-100 v-center pd-5-15 mg-b-15 mg-t-45'>

                <img className="h-70-p br-5" src={require('../icons/logo.jpg')} />

                <div className='cutm-inp-bx pd-5-15 br-5 mg-t-15'>
                    <img className="h-w-22" src={require('../icons/phone_icon.png')} />
                    <input type="number" className='mg-l-20 ft-sz-18 cutm-inp' autoComplete="new-password" placeholder='Enter Mobile Number' onChange={e => onInputValChange('mobile',e.target.value)}></input>
                </div>

                <div className='cutm-inp-bx pd-5-15 br-5 mg-t-5'>
                    <img className="h-w-22" src={require('../icons/lock_icon.png')} />
                    <input type="password" className='mg-l-20 ft-sz-18 cutm-inp' autoComplete="new-password" placeholder='Password (≥6 characters)' onChange={e => onInputValChange('password',e.target.value)}></input>
                </div>

                <div className='cutm-inp-bx pd-5-15 br-5 mg-t-5'>
                    <img className="h-w-22" src={require('../icons/lock_icon.png')} />
                    <input type="password" className='mg-l-20 ft-sz-18 cutm-inp' autoComplete="new-password" placeholder='Confirm Login Password' onChange={e => onInputValChange('confirmPassword',e.target.value)}></input>
                </div>

                <div className='cutm-inp-bx pd-5-15 br-5 mg-t-5'>
                    <img className="h-w-22" src={require('../icons/secure_icon.png')} />
                    <input type="number" className='mg-l-20 ft-sz-18 cutm-inp' autoComplete="new-password" placeholder='OTP' onChange={e => onInputValChange('verificationCode',e.target.value)}></input>

                    <div className='w-125-p h-40-p v-center ft-sz-18 br-5 cl-white bg-green' onClick={() => validateMyIP("OTP")}>{pageConst.isOTPSending ? 'Sending' : resendOTPTimeLeft > 0 ? resendOTPTimeLeft : 'OTP'}</div>
                </div>

                <div className={`w-100 mg-t-20 h-50-p ft-sz-20 v-center br-5 cl-white bx-shdw-blk ${isInputValCorrect ? 'bg-blue' : 'bg-grey-2'}`} onClick={() => validateMyIP("RESET")}>
                  <div className={`h-20-p w-20-p lodr-v ${pageConst.isLoadingShow==false ? 'hide-v' : ''}`}></div>
                  <span className={`${pageConst.isLoadingShow==true ? 'hide-v' : ''}`}>Reset Password</span>
                </div>
            </div>

          </div>
        </div>
      </div>
    );
}

export default ForgetPassword;