import React,{useState} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// import logo from './logo.svg';
import './App.css';
import './MainStyle.css';
import Home from './components/screens/Home';
import Invite from './components/screens/Invite';
import Recharge from './components/screens/Recharge';
import My from './components/screens/My';
import Login from './components/screens/Login';
import Register from './components/screens/Register';
import ForgotPassword from './components/screens/ForgotPassword';
import ChangePassword from './components/screens/ChangePassword';
import GameRecords from './components/screens/GameRecords';
import Transactions from './components/screens/Transactions';
import HelpSupport from './components/screens/HelpSupport';
import AllBankCards from './components/screens/AllBankCards';
import AddBankCard from './components/screens/AddBankCard';
import AddCryptoAddress from './components/screens/AddCryptoAddress';
import MyOrders from './components/screens/MyOrders';
import Withdraw from './components/screens/Withdraw';
import WithdrawRecords from './components/screens/WithdrawRecords';
import RechargeRecords from './components/screens/RechargeRecords';
import InviteLink from './components/screens/InviteLink';
import Rewards from './components/screens/Rewards';
import AgentReports from './components/screens/AgentReports';
import FastParity from './components/Games/FastParity';
import Spare from './components/Games/Spare';
import Parity from './components/Games/Parity';
import Dice from './components/Games/Dice';
import AndarBahar from './components/Games/AndarBahar';
import Aviator from './components/Games/Aviator';

import UnderMaintenance from './components/screens/UnderMaintenance';
import Wheelocity from './components/Games/Wheelocity';
import GoldMiner from './components/Games/GoldMiner';
import DragonTiger from './components/Games/DragonTiger';

import SuperPay from './components/payments/SuperPay';
import DeviceManager from './components/screens/DeviceManager';
import UpdateAccount from './components/screens/UpdateAccount';
import ChangeAvatar from './components/screens/ChangeAvatar';
import ChangeName from './components/screens/ChnageName';
import AllMembers from './components/screens/AllMembers';

import ClaimGiftCards from './components/screens/ClaimGiftCards';
import DailyCheckIn from './components/screens/DailyCheckIn';

function App() {

  return <>
  <Router>

    <Routes>
      <Route path='/home' element={<Home/>} />
      <Route path='/invite' element={<Invite/>} />
      <Route path='/recharge' element={<Recharge/>} />
      <Route path='/my' element={<My/>} />
      <Route path='/gamerecords' element={<GameRecords/>} />
      <Route path='/myorders' element={<MyOrders/>} />
      <Route path='/transactions' element={<Transactions/>} />
      <Route path='/LG' element={<Login/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/RG' element={<Register/>} />
      <Route path='/register' element={<Register/>} />
      <Route path='/changepassword' element={<ChangePassword/>} />
      <Route path='/forgotpassword' element={<ForgotPassword/>} />
      <Route path='/withdraw' element={<Withdraw/>} />
      <Route path='/bankcards' element={<AllBankCards/>} />
      <Route path='/addbankcard' element={<AddBankCard/>} />
      <Route path='/addcryptoaddress' element={<AddCryptoAddress/>} />
      <Route path='/withdrawrecords' element={<WithdrawRecords/>} />
      <Route path='/rechargerecords' element={<RechargeRecords/>} />
      <Route path='/invitelink' element={<InviteLink/>} />
      <Route path='/rewards' element={<Rewards/>} />
      <Route path='/todaysreports' element={<AgentReports/>} />
      <Route path='/devicemanager' element={<DeviceManager/>} />
      <Route path='/updateaccount' element={<UpdateAccount/>} />
      <Route path='/changeavatar' element={<ChangeAvatar/>} />
      <Route path='/changename' element={<ChangeName/>} />
      <Route path='/allmembers' element={<AllMembers/>} />

      <Route path='/Parity' element={<Parity/>} />
      <Route path='/Spare' element={<Spare/>} />
      <Route path='/FastParity' element={<FastParity/>} />
      <Route path='/Dice' element={<Dice/>} />
      <Route path='/AndarBahar' element={<AndarBahar/>} />
      <Route path='/Wheelocity' element={<Wheelocity/>} />
      <Route path='/GoldMiner' element={<GoldMiner/>} />
      <Route path='/Aviator' element={<Aviator/>} />
      <Route path='/DragonTiger' element={<DragonTiger/>} />
      <Route path='/helpsupport' element={<HelpSupport/>} />

      <Route path='/claimgiftcard' element={<ClaimGiftCards/>} />
      <Route path='/dailycheckin' element={<DailyCheckIn/>} />

      <Route path='/superpay' element={<SuperPay/>} />

      <Route path='/um' element={<UnderMaintenance/>} />

      {/* default page */}
      <Route path='/' element={<Login/>} />
    </Routes>

  </Router>
  </>;
}

export default App;
