import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import { AVATARS_ARR } from '../modals/Constants';
import { getStorage } from '../modals/Storage';

function ChangeAvatar(){
    const navigate = useNavigate();
    const [avatarURL, setAvatarURL] = useState("");
    const [pageConst, setConstants] = useState({
        pageTitle: "Change Avatar",
        isLoadingShow: false,
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn1"){
          navigate('/withdraw', { replace: false });
        }
    }

    const setAvatar = () =>{
        function getRandomNumber(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
        }
    
        let randomNumber = getRandomNumber(0,AVATARS_ARR.length-1);
        setAvatarURL(AVATARS_ARR[randomNumber].avatarURL);
    }
  
    useEffect(() => {
        setAvatar();
    }, [avatarURL]);

    return (
      <div className="v-center bg-black ovf-hidden">
        <div className='h-100vh res-wth bg-app-back'>
          <div className={`pr-v ovf-scrl-y hide-sb zoomAnimView`}>
           <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>

           <div className="col-view pd-20 mg-t-55 mg-b-15">

              <div className='v-center'>
                <img src={AVATARS_ARR[getStorage("avatarId")]['avatarURL']} className="h-w-70" alt="icon" />
              </div>

              <div className='w-100 g-v-3 g-r-gap-30 mg-t-45'>

              {AVATARS_ARR.map((arrayItem, index) => (
                <div className='v-center' key={arrayItem.id}>
                  <img src={arrayItem.avatarURL} className="h-w-45" alt="icon" />
                </div>
              ))}

              </div>

           </div>
          </div>
        </div>
      </div>
    );
}

export default ChangeAvatar;